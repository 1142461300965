import React, { lazy, useContext, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "./utils/toast";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PrivateRoute from "./components/login/PrivateRoute";
import IdleTimeOutHandler from "./components/session/IdleTimeOutHandler";
import { AdminContext } from "./context/AdminContext";
import Cookies from "js-cookie";

// Carga diferida (lazy loading) para optimizar la carga de componentes
const Layout = lazy(() => import("./layout/Layout"));
const Login = lazy(() => import("./pages/Login"));
window.palabra_busqueda = "";

// Componente principal de la aplicación
const App = () => {
  // Usa el contexto de administrador y obtiene estado y dispatch para manejar acciones
  const { state, dispatch } = useContext(AdminContext);

  // Controla si el usuario está activo o inactivo
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      {/* Contenedor de mensajes tipo Toast (notificaciones) */}
      <ToastContainer />

      {/* Manejador de tiempo inactivo, que detecta cuando el usuario se vuelve inactivo */}
      <IdleTimeOutHandler
        onActive={() => {
          setIsActive(true); // Cambia el estado a activo cuando el usuario interactúa
        }}
        onIdle={() => {
          setIsActive(false); // Cambia el estado a inactivo cuando no hay interacción
        }}
      />

      {/* Enrutador principal */}
      <Router>
        {/* Anunciador de accesibilidad para navegación, ayuda a usuarios con lectores de pantalla */}
        <AccessibleNavigationAnnouncer />
        {isActive ? (
          // Si el usuario está activo, se muestran las rutas principales
          <Switch>
            {/* Ruta para la página de inicio de sesión */}
            <Route path="/login" component={Login} />

            {/* Ruta protegida para acceder al layout principal de la aplicación */}
            <PrivateRoute>
              <Route path="/" component={Layout} />
            </PrivateRoute>

            {/* Redirección a la página de login si el usuario no está autenticado */}
            <Redirect exact from="/" to="/login" />
          </Switch>
        ) : (
          // Si el usuario está inactivo, realiza un cierre de sesión automático
          // Borra cookies de sesión
          // Limpia datos relevantes del LocalStorage
          (dispatch({ type: "USER_LOGOUT" }), // Dispatch para cerrar sesión en el contexto
          Cookies.remove("adminInfo"), 
          Cookies.remove("materias_data"),
          Cookies.remove("grupos"),
          Cookies.remove("searching_mode"),
          localStorage.removeItem("lista_titulos_buscar"), 
          localStorage.removeItem("idioma_seleccionado"),
          localStorage.removeItem("grado_id"),
          localStorage.removeItem("title_id"),
          localStorage.removeItem("items_lista_materias"),
          localStorage.removeItem("title_id_back"),
          localStorage.removeItem("items_titulos_conteo"),
          localStorage.removeItem("permisos_data"),
          localStorage.removeItem("data_title_filter"),
          localStorage.removeItem("usuario_imagen"),
          localStorage.removeItem("title_search_text"),
          localStorage.removeItem("title_search_text_temp"),
          localStorage.removeItem("imagen_seleccionada"),
          localStorage.removeItem("materia_id"),
          localStorage.removeItem("colegio_actual"),
          localStorage.removeItem("items_titulos_conteo_grado"),
          localStorage.removeItem("grupo_id_selected"),
          localStorage.removeItem("items_titulos_conteo_grado_en"),
          localStorage.removeItem("title_materia_search"),
          localStorage.removeItem("items_lista_conteo"),
          localStorage.removeItem("materia_id_temp"),
          localStorage.removeItem("grupo_usuario_data"),
          localStorage.removeItem("grupo_nombre_data"),
          setTimeout(window.location.reload(), 2000)) // Recarga la página después de limpiar los datos
        )}
      </Router>
    </>
  );
};

export default App;
