import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Windmill } from '@windmill/react-ui';
import './assets/css/tailwind.css';
import './assets/css/output.css';
import './assets/css/custom.css';
import '@pathofdev/react-tag-input/build/index.css';
import App from './App';
import myTheme from './assets/theme/myTheme';
import { AdminProvider } from './context/AdminContext';
import { SidebarProvider } from './context/SidebarContext';
import ThemeSuspense from './components/theme/ThemeSuspense';
import { SearchingProvider } from './context/SearchingContext';
// import * as serviceWorker from './serviceWorker';

// if (process.env.NODE_ENV !== "production") {
//   const axe = require("react-axe");
//   axe(React, ReactDOM, 1000);
// }

ReactDOM.render(
  <AdminProvider>
      <SidebarProvider>
          <SearchingProvider>
              {/* Suspense permite mostrar un fallback mientras se carga el componente */}
              <Suspense fallback={<ThemeSuspense />}>
                  {/* Windmill aplica el tema y las preferencias visuales en toda la aplicación */}
                  <Windmill usePreferences theme={myTheme}>
                      {/* Componente principal de la aplicación App */}
                      <App />
                  </Windmill>
              </Suspense>
          </SearchingProvider>
      </SidebarProvider>
  </AdminProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
